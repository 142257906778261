body {
  background-color: #f0f2f5 !important;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
  .ant-card h1 {
    text-align: center;
  }
}

#root,
body,
html {
  font-family: Montserrat, sans-serif !important;
  color: #979da7 !important;
  line-height: 1.8 !important;
}

.ant-statistic-content {
  font-family: Montserrat, sans-serif !important;
  line-height: 1.8 !important;
}

.ant-card h3 {
  font-family: Montserrat, sans-serif !important;
  color: #979da7 !important;
  line-height: 3 !important;
  font-weight: 400;
  font-size: 110%;
}
h3.subtitle {
  font-size: 90%;
}
.ant-card {
  position: relative;
}

#green h3, #green .ant-statistic-content-value-int{color: green !important; font-weight: bold;}

h3.subtitle {
  margin-top: 0px !important;
  margin-bottom: -50px !important;
}
.ant-card-body {
  min-height: 540px;
}

.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0 8px 0 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus,
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #e8e8e8;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-full .ant-rate-star-second,
.ant-rate-star-half .ant-rate-star-first {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}
.antd-pro-components-charts-chart-card-index-chartCard {
  position: relative;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-chartTop {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-chartTopMargin {
  margin-bottom: 12px;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-chartTopHasMargin {
  margin-bottom: 20px;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-metaWrap {
  float: left;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-avatar {
  position: relative;
  top: 4px;
  float: left;
  margin-right: 20px;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-avatar
  img {
  border-radius: 100%;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-meta {
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 0;
  border-radius: 20px;
  margin: 0 0 24px;
  font-weight: 500;
  letter-spacing: 1px;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-action {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  cursor: pointer;
  color: #d9e2ef;
  border-radius: 50%;
  padding: 0;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-total {
  height: 38px;
  margin-top: 4px;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 30px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  font-weight: 700;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-content {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-contentFixed {
  bottom: 0;
  left: 0;
  width: 100%;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-footer {
  margin: 0;
  padding-top: 24px;
  font-weight: 300;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-footer
  > * {
  position: relative;
}
.antd-pro-components-charts-chart-card-index-chartCard
  .antd-pro-components-charts-chart-card-index-footerMargin {
  margin-top: 24px;
}
.antd-pro-components-charts-field-index-field {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.antd-pro-components-charts-field-index-field
  .antd-pro-components-charts-field-index-label,
.antd-pro-components-charts-field-index-field
  .antd-pro-components-charts-field-index-number {
  font-size: 14px;
  line-height: 22px;
}
.antd-pro-components-charts-field-index-field
  .antd-pro-components-charts-field-index-number {
  margin-left: 12px;
  background: rgba(0, 0, 0, 0.2);
  font-weight: 700;
  padding: 0 12px;
  border-radius: 89px;
  display: inline-block;
}
.antd-pro-components-charts-index-miniChart {
  position: relative;
  width: 100%;
}
.antd-pro-components-charts-index-miniChart
  .antd-pro-components-charts-index-chartContent {
  bottom: -28px;
  width: 100%;
}
.antd-pro-components-charts-index-miniChart
  .antd-pro-components-charts-index-chartContent
  > div {
  margin: 0 -5px;
  overflow: hidden;
}
.antd-pro-components-charts-index-miniChart
  .antd-pro-components-charts-index-chartLoading {
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -7px;
}
.antd-pro-components-charts-pie-index-pie {
  position: relative;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-chart {
  position: relative;
}
.antd-pro-components-charts-pie-index-pie.antd-pro-components-charts-pie-index-hasLegend
  .antd-pro-components-charts-pie-index-chart {
  width: calc(100% - 240px);
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-legend {
  position: absolute;
  top: 50%;
  right: 0;
  min-width: 200px;
  margin: 0 20px;
  padding: 0;
  list-style: none;
  transform: translateY(-50%);
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-legend
  li {
  height: 22px;
  margin-bottom: 16px;
  line-height: 22px;
  cursor: pointer;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-legend
  li:last-child {
  margin-bottom: 0;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 8px;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-line {
  display: inline-block;
  width: 1px;
  height: 16px;
  margin-right: 8px;
  background-color: #e8e8e8;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-legendTitle {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-percent {
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-value {
  position: absolute;
  right: 0;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-title {
  margin-bottom: 8px;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-total {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 62px;
  text-align: center;
  transform: translate(-50%, -50%);
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-total
  > h4 {
  height: 22px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.antd-pro-components-charts-pie-index-pie
  .antd-pro-components-charts-pie-index-total
  > p {
  display: block;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.2em;
  line-height: 32px;
  white-space: nowrap;
}
.antd-pro-components-charts-pie-index-legendBlock.antd-pro-components-charts-pie-index-hasLegend
  .antd-pro-components-charts-pie-index-chart {
  width: 100%;
  margin: 0 0 32px;
}
.antd-pro-components-charts-pie-index-legendBlock
  .antd-pro-components-charts-pie-index-legend {
  position: relative;
  transform: none;
}
.antd-pro-components-charts-radar-index-radar
  .antd-pro-components-charts-radar-index-legend {
  margin-top: 16px;
}
.antd-pro-components-charts-radar-index-radar
  .antd-pro-components-charts-radar-index-legend
  .antd-pro-components-charts-radar-index-legendItem {
  position: relative;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.antd-pro-components-charts-radar-index-radar
  .antd-pro-components-charts-radar-index-legend
  .antd-pro-components-charts-radar-index-legendItem
  p {
  margin: 0;
}
.antd-pro-components-charts-radar-index-radar
  .antd-pro-components-charts-radar-index-legend
  .antd-pro-components-charts-radar-index-legendItem
  h6 {
  margin-top: 4px;
  margin-bottom: 0;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 32px;
}
.antd-pro-components-charts-radar-index-radar
  .antd-pro-components-charts-radar-index-legend
  .antd-pro-components-charts-radar-index-legendItem:after {
  position: absolute;
  top: 8px;
  right: 0;
  width: 1px;
  height: 40px;
  background-color: #e8e8e8;
  content: "";
}
.antd-pro-components-charts-radar-index-radar
  .antd-pro-components-charts-radar-index-legend
  > :last-child
  .antd-pro-components-charts-radar-index-legendItem:after {
  display: none;
}
.antd-pro-components-charts-radar-index-radar
  .antd-pro-components-charts-radar-index-legend
  .antd-pro-components-charts-radar-index-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 10px;
}
.antd-pro-components-charts-mini-progress-index-miniProgress {
  position: relative;
  width: 100%;
  padding: 5px 0;
}
.antd-pro-components-charts-mini-progress-index-miniProgress
  .antd-pro-components-charts-mini-progress-index-progressWrap {
  position: relative;
  background-color: #f5f5f5;
}
.antd-pro-components-charts-mini-progress-index-miniProgress
  .antd-pro-components-charts-mini-progress-index-progress {
  width: 0;
  height: 100%;
  background-color: #1561ae;
  border-radius: 1px 0 0 1px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.antd-pro-components-charts-mini-progress-index-miniProgress
  .antd-pro-components-charts-mini-progress-index-target {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  width: 20px;
}
.antd-pro-components-charts-mini-progress-index-miniProgress
  .antd-pro-components-charts-mini-progress-index-target
  span {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 4px;
  border-radius: 100px;
}
.antd-pro-components-charts-mini-progress-index-miniProgress
  .antd-pro-components-charts-mini-progress-index-target
  span:last-child {
  top: auto;
  bottom: 0;
}
.antd-pro-components-charts-water-wave-index-waterWave {
  position: relative;
  display: inline-block;
  transform-origin: left;
}
.antd-pro-components-charts-water-wave-index-waterWave
  .antd-pro-components-charts-water-wave-index-text {
  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;
  text-align: center;
}
.antd-pro-components-charts-water-wave-index-waterWave
  .antd-pro-components-charts-water-wave-index-text
  span {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.antd-pro-components-charts-water-wave-index-waterWave
  .antd-pro-components-charts-water-wave-index-text
  h4 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 32px;
}
.antd-pro-components-charts-water-wave-index-waterWave
  .antd-pro-components-charts-water-wave-index-waterWaveCanvasWrapper {
  transform: scale(0.5);
  transform-origin: 0 0;
}
.antd-pro-components-charts-tag-cloud-index-tagCloud {
  overflow: hidden;
}
.antd-pro-components-charts-tag-cloud-index-tagCloud canvas {
  transform-origin: 0 0;
}
.antd-pro-components-charts-timeline-chart-index-timelineChart {
  background: #fff;
}
.antd-pro-components-trend-index-trendItem {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
}
.antd-pro-components-trend-index-trendItem
  .antd-pro-components-trend-index-down,
.antd-pro-components-trend-index-trendItem .antd-pro-components-trend-index-up {
  position: relative;
  top: 1px;
  margin-left: 4px;
}
.antd-pro-components-trend-index-trendItem
  .antd-pro-components-trend-index-down
  i,
.antd-pro-components-trend-index-trendItem
  .antd-pro-components-trend-index-up
  i {
  font-size: 12px;
  transform: scale(0.83);
}
.antd-pro-components-trend-index-trendItem .antd-pro-components-trend-index-up {
  color: #f5222d;
}
.antd-pro-components-trend-index-trendItem
  .antd-pro-components-trend-index-down {
  top: -1px;
  color: #52c41a;
}
.antd-pro-components-trend-index-trendItem.antd-pro-components-trend-index-trendItemGrey
  .antd-pro-components-trend-index-down,
.antd-pro-components-trend-index-trendItem.antd-pro-components-trend-index-trendItemGrey
  .antd-pro-components-trend-index-up {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-trend-index-trendItem.antd-pro-components-trend-index-reverseColor
  .antd-pro-components-trend-index-up {
  color: #52c41a;
}
.antd-pro-components-trend-index-trendItem.antd-pro-components-trend-index-reverseColor
  .antd-pro-components-trend-index-down {
  color: #f5222d;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-suffix {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  font-style: normal;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoTitle {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  transition: all 0.3s;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoSubTitle {
  height: 22px;
  overflow: hidden;
  color: #111;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  word-break: break-all;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoValue {
  margin-top: 4px;
  overflow: hidden;
  font-size: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoValue
  > span {
  display: inline-block;
  height: 32px;
  margin-right: 32px;
  font-size: 14px;
  line-height: 32px;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoValue
  .antd-pro-components-number-info-index-subTotal {
  margin-right: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  vertical-align: top;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoValue
  .antd-pro-components-number-info-index-subTotal
  i {
  margin-left: 4px;
  font-size: 12px;
  transform: scale(0.82);
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoValue
  .antd-pro-components-number-info-index-subTotal
  .anticon-caret-up {
  color: #f5222d;
}
.antd-pro-components-number-info-index-numberInfo
  .antd-pro-components-number-info-index-numberInfoValue
  .antd-pro-components-number-info-index-subTotal
  .anticon-caret-down {
  color: #52c41a;
}
.antd-pro-components-number-info-index-numberInfolight
  .antd-pro-components-number-info-index-numberInfoValue
  > span {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-active-chart-index-activeChart {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.antd-pro-components-active-chart-index-activeChartGrid p {
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}
.antd-pro-components-active-chart-index-activeChartGrid p span {
  font-weight: 400;
  font-size: 12px;
}
.antd-pro-components-active-chart-index-activeChartGrid
  p
  .antd-pro-components-active-chart-index-anticon-caret-down {
  font-size: 13px;
  color: red;
}
.antd-pro-components-active-chart-index-activeChartGrid
  p
  .antd-pro-components-active-chart-index-anticon.antd-pro-components-active-chart-index-anticon-caret-up {
  font-size: 13px;
  color: green;
}
.antd-pro-components-active-chart-index-activeChartGrid p:last-child {
  top: 105px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}
.antd-pro-components-active-chart-index-activeChartLegend {
  position: relative;
  height: 20px;
  margin-top: 8px;
  font-size: 0;
  line-height: 20px;
}
.antd-pro-components-active-chart-index-activeChartLegend span {
  display: inline-block;
  width: 33.33%;
  font-size: 12px;
  text-align: center;
}
.antd-pro-components-active-chart-index-activeChartLegend span:first-child {
  text-align: left;
}
.antd-pro-components-active-chart-index-activeChartLegend span:last-child {
  text-align: right;
}
.antd-pro-components-active-chart-index-dashedLine {
  position: relative;
  top: -70px;
  left: -3px;
  height: 1px;
}
.antd-pro-components-active-chart-index-dashedLine
  .antd-pro-components-active-chart-index-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent 50%, #e9e9e9 0);
  background-size: 6px;
}
.antd-pro-components-active-chart-index-dashedLine:last-child {
  top: -36px;
}
.antd-pro-pages-dashboard-lite-index-boxProfileNotf {
  display: flex;
  flex-wrap: nowrap;
}
.antd-pro-pages-dashboard-lite-index-boxProfileNotf div {
  width: auto;
  margin: 0;
  text-align: left;
}
.antd-pro-pages-dashboard-lite-index-boxProfileNotf div:last-child {
  margin: 0 0 12px 24px;
}
.antd-pro-pages-dashboard-lite-index-boxProfileNotf div span img {
  margin-right: 24px;
  display: block;
}
.antd-pro-pages-dashboard-lite-index-boxProfileNotf div h3 {
  margin-bottom: 12px;
  font-size: inherit;
}
.antd-pro-pages-dashboard-lite-index-boxProfileNotf div h3 span {
  text-align: right;
  background: #f5f5f5;
  padding: 3px 12px;
  font-size: 11px;
  border-radius: 20px;
  color: #999;
  margin-left: 12px;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle {
  margin-top: 25px;
  padding: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08);
  min-height: 100px;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h5 {
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 0;
  border-radius: 20px;
  margin: 0 0 24px;
  font-weight: 500;
  letter-spacing: 1px;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h1 {
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 0;
  border-radius: 20px;
  margin: 0 0 24px;
  font-weight: 500;
  letter-spacing: 1px;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h1 i {
  float: right;
  display: inline-block;
  margin-left: 12px;
  height: 24px;
  width: 24px;
  line-height: 26px;
  text-align: center;
  background: #f5f5f5;
  border-radius: 50%;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h1 button {
  float: right;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h1 button i {
  background: transparent;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h1 h5 {
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 24px;
  margin: 0;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h1 td {
  padding: 12px 24px !important;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle h1 th {
  padding: 24px !important;
  background: red !important;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport:last-child {
  margin: 0 24px 0 0;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport
  div {
  width: 50%;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #eee;
  padding: 24px 0;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport
  div
  h1 {
  font-size: 30px;
  margin: 0 0 12px;
  font-weight: 700;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport
  div
  h1
  small {
  background: #333;
  font-size: 16px;
  color: #fff;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  position: relative;
  top: -12px;
  font-weight: 100;
  left: 6px;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport
  div:last-child {
  margin: 0;
  border-left: 1px solid #eee;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport
  div
  span
  img {
  margin-right: 24px;
  display: block;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport
  div
  h3 {
  margin-bottom: 12px;
  font-size: inherit;
}
.antd-pro-pages-dashboard-lite-index-boxNonTitle
  .antd-pro-pages-dashboard-lite-index-boxReport
  div
  h3
  span {
  text-align: right;
  background: #f5f5f5;
  padding: 3px 12px;
  font-size: 11px;
  border-radius: 20px;
  color: #999;
  margin-left: 12px;
}
.antd-pro-pages-dashboard-lite-index-ProducaAvant
  .antd-pro-pages-dashboard-lite-index-ant-tag {
  font-size: 11px;
  border-radius: 30px;
  border: none;
  padding: 0 15px;
}
.antd-pro-pages-dashboard-lite-index-iconFlag {
  position: relative;
}
.antd-pro-pages-dashboard-lite-index-iconFlag i {
  position: absolute;
  top: 6px;
  left: -65px;
}
.antd-pro-pages-dashboard-lite-index-listFlag ul li {
  padding: 18px 0;
  margin: 0 24px 0 0;
  border: none !important;
}
.antd-pro-pages-dashboard-lite-index-darkCard {
  background: rgba(145, 127, 127, 0.26) !important;
  color: #fff;
  background: transparent;
}
.antd-pro-pages-dashboard-lite-index-darkCard div,
.antd-pro-pages-dashboard-lite-index-darkCard em,
.antd-pro-pages-dashboard-lite-index-darkCard h4,
.antd-pro-pages-dashboard-lite-index-darkCard p,
.antd-pro-pages-dashboard-lite-index-darkCard span {
  color: #fff !important;
  border: 0;
}
.antd-pro-pages-dashboard-lite-index-darkCard
  .antd-pro-pages-dashboard-lite-index-ant-card-head {
  color: #fff !important;
  border-bottom: none !important;
}
.antd-pro-pages-dashboard-lite-index-darkCard
  .antd-pro-pages-dashboard-lite-index-ant-card-head
  .antd-pro-pages-dashboard-lite-index-ant-card-head-title {
  color: #fff !important;
}
.antd-pro-pages-dashboard-lite-index-darkCard
  .antd-pro-pages-dashboard-lite-index-mapBgCard {
  padding: 15px;
  margin: 0 0 60px;
}
div
  .antd-pro-pages-dashboard-lite-index-darkCard
  .antd-pro-pages-dashboard-lite-index-mapBgCard:first-child
  span {
  font-size: 14px !important;
}
.antd-pro-pages-dashboard-lite-index-darkCard
  .antd-pro-pages-dashboard-lite-index-mapBgCard
  span {
  font-size: 2em;
}
.antd-pro-pages-dashboard-lite-index-antd-pro-components-charts-pie-index-pie
  .antd-pro-pages-dashboard-lite-index-antd-pro-components-charts-pie-index-value {
  background: #314659;
  padding: 2px 4px;
  border-radius: 40px;
}
.antd-pro-pages-dashboard-lite-index-iconGroup i {
  margin-left: 16px;
  cursor: pointer;
  transition: color 0.32s;
}
.antd-pro-pages-dashboard-lite-index-iconGroup i:hover {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-pages-dashboard-lite-index-rankingList {
  margin: 25px 0 0;
  padding: 0;
  list-style: none;
}
.antd-pro-pages-dashboard-lite-index-rankingList li {
  zoom: 1;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.antd-pro-pages-dashboard-lite-index-rankingList li:after,
.antd-pro-pages-dashboard-lite-index-rankingList li:before {
  content: " ";
  display: table;
}
.antd-pro-pages-dashboard-lite-index-rankingList li:after {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
}
.antd-pro-pages-dashboard-lite-index-rankingList li span {
  font-size: 14px;
  line-height: 22px;
}
.antd-pro-pages-dashboard-lite-index-rankingList
  li
  .antd-pro-pages-dashboard-lite-index-rankingItemNumber {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 1.5px;
  margin-right: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 20px;
}
.antd-pro-pages-dashboard-lite-index-rankingList
  li
  .antd-pro-pages-dashboard-lite-index-rankingItemNumber.antd-pro-pages-dashboard-lite-index-active {
  background-color: #314659;
}
.antd-pro-pages-dashboard-lite-index-rankingList
  li
  .antd-pro-pages-dashboard-lite-index-rankingItemTitle {
  flex: 1 1;
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.antd-pro-pages-dashboard-lite-index-salesExtra {
  display: inline-block;
  margin-right: 24px;
}
.antd-pro-pages-dashboard-lite-index-salesExtra a {
  margin-left: 24px;
}
.antd-pro-pages-dashboard-lite-index-salesExtra a:hover {
  color: #1561ae;
}
.antd-pro-pages-dashboard-lite-index-salesExtra
  a.antd-pro-pages-dashboard-lite-index-currentDate {
  color: #1561ae;
}
.antd-pro-pages-dashboard-lite-index-salesCard
  .antd-pro-pages-dashboard-lite-index-salesBar {
  padding: 0 0 32px 32px;
}
.antd-pro-pages-dashboard-lite-index-salesCard
  .antd-pro-pages-dashboard-lite-index-salesRank {
  padding: 0 32px 32px 72px;
}
.antd-pro-pages-dashboard-lite-index-salesCard .ant-tabs-bar {
  padding-left: 16px;
}
.antd-pro-pages-dashboard-lite-index-salesCard
  .ant-tabs-bar
  .ant-tabs-nav
  .ant-tabs-tab {
  padding-top: 16px;
  padding-bottom: 14px;
  line-height: 24px;
}
.antd-pro-pages-dashboard-lite-index-salesCard .ant-tabs-extra-content {
  padding-right: 24px;
  line-height: 55px;
}
.antd-pro-pages-dashboard-lite-index-salesCard .ant-card-head {
  position: relative;
}
.antd-pro-pages-dashboard-lite-index-salesCard .ant-card-head-title {
  align-items: normal;
}
.antd-pro-pages-dashboard-lite-index-salesCardExtra {
  height: inherit;
}
.antd-pro-pages-dashboard-lite-index-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}
.antd-pro-pages-dashboard-lite-index-offlineCard .ant-tabs-ink-bar {
  bottom: auto;
}
.antd-pro-pages-dashboard-lite-index-offlineCard .ant-tabs-bar {
  border-bottom: none;
}
.antd-pro-pages-dashboard-lite-index-offlineCard
  .ant-tabs-nav-container-scrolling {
  padding-right: 40px;
  padding-left: 40px;
}
.antd-pro-pages-dashboard-lite-index-offlineCard
  .ant-tabs-tab-prev-icon:before {
  position: relative;
  left: 6px;
}
.antd-pro-pages-dashboard-lite-index-offlineCard
  .ant-tabs-tab-next-icon:before {
  position: relative;
  right: 6px;
}
.antd-pro-pages-dashboard-lite-index-twoColLayout
  .antd-pro-pages-dashboard-lite-index-salesCard {
  height: calc(100% - 24px);
}
.antd-pro-pages-dashboard-lite-index-twoColLayout
  div[class^="ant-col"]:last-child {
  position: absolute\9;
  right: 0\9;
  height: 100%\9;
}
.antd-pro-pages-dashboard-lite-index-twoColLayout .ant-row {
  position: relative\9;
  display: flex;
  display: block\9;
  flex-flow: row wrap;
}
.antd-pro-pages-dashboard-lite-index-trendText {
  margin-left: 8px;
}
@media screen and (max-width: 992px) {
  .antd-pro-pages-dashboard-lite-index-salesExtra {
    display: none;
  }
  .antd-pro-pages-dashboard-lite-index-rankingList li span:first-child {
    margin-right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .antd-pro-pages-dashboard-lite-index-rankingTitle {
    margin-top: 16px;
  }
  .antd-pro-pages-dashboard-lite-index-salesCard
    .antd-pro-pages-dashboard-lite-index-salesBar {
    padding: 16px;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-pages-dashboard-lite-index-salesExtraWrap {
    display: none;
  }
  .antd-pro-pages-dashboard-lite-index-salesCard .ant-tabs-content {
    padding-top: 30px;
  }
}
.antd-pro-pages-dashboard-lite-index-mapChart {
  height: 452px;
  padding-top: 24px;
  text-align: center;
}
.antd-pro-pages-dashboard-lite-index-mapChart img {
  display: inline-block;
  max-width: 100%;
  max-height: 437px;
}
.antd-pro-pages-dashboard-lite-index-pieCard .pie-stat {
  font-size: 24px !important;
}
@media screen and (max-width: 992px) {
  .antd-pro-pages-dashboard-lite-index-mapChart {
    height: auto;
  }
}
.antd-pro-pages-dashboard-lite-index-numberText small {
  font-weight: 400;
  font-size: 12px;
}

@import url(//cdn.jsdelivr.net/npm/dripicons@2.0.0/webfont/webfont.css);
body,
html {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
:after,
:before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  font-feature-settings: "tnum";
}
[tabindex="-1"]:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type="number"],
input[type="password"],
input[type="text"],
textarea {
  -webkit-appearance: none;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1561ae;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #347cba;
}
a:active {
  color: #094487;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
code,
kbd,
pre,
samp {
  font-size: 1em;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1561ae;
}
.clearfix {
  zoom: 1;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon:before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin:before {
  display: inline-block;
  animation: loadingCircle 1s linear infinite;
}
.anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s linear infinite;
}
.fade-appear,
.fade-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-appear.fade-appear-active,
.fade-enter.fade-enter-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.fade-appear,
.fade-enter {
  opacity: 0;
  animation-timing-function: linear;
}
.fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.move-up-appear,
.move-up-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-appear.move-up-appear-active,
.move-up-enter.move-up-enter-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-up-appear,
.move-up-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-appear,
.move-down-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-appear.move-down-appear-active,
.move-down-enter.move-down-enter-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-down-appear,
.move-down-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-appear,
.move-left-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-appear.move-left-appear-active,
.move-left-enter.move-left-enter-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-left-appear,
.move-left-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-appear,
.move-right-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-appear.move-right-appear-active,
.move-right-enter.move-right-enter-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-right-appear,
.move-right-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  to {
    transform: rotate(1turn);
  }
}
[ant-click-animating-without-extra-node="true"],
[ant-click-animating="true"] {
  position: relative;
}
.ant-click-animating-node,
[ant-click-animating-without-extra-node="true"]:after {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  display: block;
  border: 0 solid #1561ae;
  border-radius: inherit;
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
    waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  pointer-events: none;
  content: "";
}
@keyframes waveEffect {
  to {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px;
  }
}
@keyframes fadeEffect {
  to {
    opacity: 0;
  }
}
.slide-up-appear,
.slide-up-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-appear.slide-up-appear-active,
.slide-up-enter.slide-up-enter-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-up-appear,
.slide-up-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-appear,
.slide-down-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-appear.slide-down-appear-active,
.slide-down-enter.slide-down-enter-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-down-appear,
.slide-down-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-appear,
.slide-left-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-appear.slide-left-appear-active,
.slide-left-enter.slide-left-enter-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-left-appear,
.slide-left-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-appear,
.slide-right-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-appear.slide-right-appear-active,
.slide-right-enter.slide-right-enter-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-right-appear,
.slide-right-enter {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
}
.swing-appear,
.swing-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.swing-appear.swing-appear-active,
.swing-enter.swing-enter-active {
  animation-name: antSwingIn;
  animation-play-state: running;
}
@keyframes antSwingIn {
  0%,
  to {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}
.zoom-appear,
.zoom-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-appear.zoom-appear-active,
.zoom-enter.zoom-enter-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-appear,
.zoom-enter {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-appear,
.zoom-big-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-appear.zoom-big-appear-active,
.zoom-big-enter.zoom-big-enter-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-appear,
.zoom-big-enter {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-appear,
.zoom-big-fast-enter {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-appear.zoom-big-fast-appear-active,
.zoom-big-fast-enter.zoom-big-fast-enter-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-appear,
.zoom-big-fast-enter {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-appear,
.zoom-up-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-appear.zoom-up-appear-active,
.zoom-up-enter.zoom-up-enter-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-up-appear,
.zoom-up-enter {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-appear,
.zoom-down-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-appear.zoom-down-appear-active,
.zoom-down-enter.zoom-down-enter-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-down-appear,
.zoom-down-enter {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-appear,
.zoom-left-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-appear.zoom-left-appear-active,
.zoom-left-enter.zoom-left-enter-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-left-appear,
.zoom-left-enter {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-appear,
.zoom-right-enter {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-appear.zoom-right-appear-active,
.zoom-right-enter.zoom-right-enter-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-right-appear,
.zoom-right-enter {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0;
  }
  to {
    transform: scale(0.8);
    transform-origin: 50% 0;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0 50%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 0 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0 50%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 0 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  to {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse {
  overflow: hidden;
}
.ant-motion-collapse-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn:focus,
.ant-btn:hover {
  color: #347cba;
  background-color: #fff;
  border-color: #347cba;
}
.ant-btn:focus > a:only-child,
.ant-btn:hover > a:only-child {
  color: currentColor;
}
.ant-btn:focus > a:only-child:after,
.ant-btn:hover > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn.active,
.ant-btn:active {
  color: #094487;
  background-color: #fff;
  border-color: #094487;
}
.ant-btn.active > a:only-child,
.ant-btn:active > a:only-child {
  color: currentColor;
}
.ant-btn.active > a:only-child:after,
.ant-btn:active > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-disabled,
.ant-btn-disabled.active,
.ant-btn-disabled:active,
.ant-btn-disabled:focus,
.ant-btn-disabled:hover,
.ant-btn.disabled,
.ant-btn.disabled.active,
.ant-btn.disabled:active,
.ant-btn.disabled:focus,
.ant-btn.disabled:hover,
.ant-btn[disabled],
.ant-btn[disabled].active,
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-disabled.active > a:only-child,
.ant-btn-disabled:active > a:only-child,
.ant-btn-disabled:focus > a:only-child,
.ant-btn-disabled:hover > a:only-child,
.ant-btn-disabled > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn.disabled > a:only-child,
.ant-btn[disabled].active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-disabled.active > a:only-child:after,
.ant-btn-disabled:active > a:only-child:after,
.ant-btn-disabled:focus > a:only-child:after,
.ant-btn-disabled:hover > a:only-child:after,
.ant-btn-disabled > a:only-child:after,
.ant-btn.disabled.active > a:only-child:after,
.ant-btn.disabled:active > a:only-child:after,
.ant-btn.disabled:focus > a:only-child:after,
.ant-btn.disabled:hover > a:only-child:after,
.ant-btn.disabled > a:only-child:after,
.ant-btn[disabled].active > a:only-child:after,
.ant-btn[disabled]:active > a:only-child:after,
.ant-btn[disabled]:focus > a:only-child:after,
.ant-btn[disabled]:hover > a:only-child:after,
.ant-btn[disabled] > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn.active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  text-decoration: none;
  background: #fff;
}
.ant-btn > i,
.ant-btn > span {
  display: inline-block;
  pointer-events: none;
}
.ant-btn-primary {
  color: #fff;
  background-color: #1561ae;
  border-color: #1561ae;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #fff;
  background-color: #347cba;
  border-color: #347cba;
}
.ant-btn-primary:focus > a:only-child,
.ant-btn-primary:hover > a:only-child {
  color: currentColor;
}
.ant-btn-primary:focus > a:only-child:after,
.ant-btn-primary:hover > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-primary.active,
.ant-btn-primary:active {
  color: #fff;
  background-color: #094487;
  border-color: #094487;
}
.ant-btn-primary.active > a:only-child,
.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary.active > a:only-child:after,
.ant-btn-primary:active > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-primary-disabled,
.ant-btn-primary-disabled.active,
.ant-btn-primary-disabled:active,
.ant-btn-primary-disabled:focus,
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled,
.ant-btn-primary.disabled.active,
.ant-btn-primary.disabled:active,
.ant-btn-primary.disabled:focus,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled],
.ant-btn-primary[disabled].active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-primary-disabled > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled].active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-primary-disabled.active > a:only-child:after,
.ant-btn-primary-disabled:active > a:only-child:after,
.ant-btn-primary-disabled:focus > a:only-child:after,
.ant-btn-primary-disabled:hover > a:only-child:after,
.ant-btn-primary-disabled > a:only-child:after,
.ant-btn-primary.disabled.active > a:only-child:after,
.ant-btn-primary.disabled:active > a:only-child:after,
.ant-btn-primary.disabled:focus > a:only-child:after,
.ant-btn-primary.disabled:hover > a:only-child:after,
.ant-btn-primary.disabled > a:only-child:after,
.ant-btn-primary[disabled].active > a:only-child:after,
.ant-btn-primary[disabled]:active > a:only-child:after,
.ant-btn-primary[disabled]:focus > a:only-child:after,
.ant-btn-primary[disabled]:hover > a:only-child:after,
.ant-btn-primary[disabled] > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #347cba;
  border-left-color: #347cba;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #347cba;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary + .ant-btn-primary,
.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
  border-left-color: #347cba;
}
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-ghost:focus,
.ant-btn-ghost:hover {
  color: #347cba;
  background-color: transparent;
  border-color: #347cba;
}
.ant-btn-ghost:focus > a:only-child,
.ant-btn-ghost:hover > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:focus > a:only-child:after,
.ant-btn-ghost:hover > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-ghost.active,
.ant-btn-ghost:active {
  color: #094487;
  background-color: transparent;
  border-color: #094487;
}
.ant-btn-ghost.active > a:only-child,
.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost.active > a:only-child:after,
.ant-btn-ghost:active > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-ghost-disabled,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled].active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost-disabled.active > a:only-child,
.ant-btn-ghost-disabled:active > a:only-child,
.ant-btn-ghost-disabled:focus > a:only-child,
.ant-btn-ghost-disabled:hover > a:only-child,
.ant-btn-ghost-disabled > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-ghost-disabled.active > a:only-child:after,
.ant-btn-ghost-disabled:active > a:only-child:after,
.ant-btn-ghost-disabled:focus > a:only-child:after,
.ant-btn-ghost-disabled:hover > a:only-child:after,
.ant-btn-ghost-disabled > a:only-child:after,
.ant-btn-ghost.disabled.active > a:only-child:after,
.ant-btn-ghost.disabled:active > a:only-child:after,
.ant-btn-ghost.disabled:focus > a:only-child:after,
.ant-btn-ghost.disabled:hover > a:only-child:after,
.ant-btn-ghost.disabled > a:only-child:after,
.ant-btn-ghost[disabled].active > a:only-child:after,
.ant-btn-ghost[disabled]:active > a:only-child:after,
.ant-btn-ghost[disabled]:focus > a:only-child:after,
.ant-btn-ghost[disabled]:hover > a:only-child:after,
.ant-btn-ghost[disabled] > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed:focus,
.ant-btn-dashed:hover {
  color: #347cba;
  background-color: #fff;
  border-color: #347cba;
}
.ant-btn-dashed:focus > a:only-child,
.ant-btn-dashed:hover > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:focus > a:only-child:after,
.ant-btn-dashed:hover > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed.active,
.ant-btn-dashed:active {
  color: #094487;
  background-color: #fff;
  border-color: #094487;
}
.ant-btn-dashed.active > a:only-child,
.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed.active > a:only-child:after,
.ant-btn-dashed:active > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed-disabled,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled].active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed-disabled.active > a:only-child,
.ant-btn-dashed-disabled:active > a:only-child,
.ant-btn-dashed-disabled:focus > a:only-child,
.ant-btn-dashed-disabled:hover > a:only-child,
.ant-btn-dashed-disabled > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dashed-disabled.active > a:only-child:after,
.ant-btn-dashed-disabled:active > a:only-child:after,
.ant-btn-dashed-disabled:focus > a:only-child:after,
.ant-btn-dashed-disabled:hover > a:only-child:after,
.ant-btn-dashed-disabled > a:only-child:after,
.ant-btn-dashed.disabled.active > a:only-child:after,
.ant-btn-dashed.disabled:active > a:only-child:after,
.ant-btn-dashed.disabled:focus > a:only-child:after,
.ant-btn-dashed.disabled:hover > a:only-child:after,
.ant-btn-dashed.disabled > a:only-child:after,
.ant-btn-dashed[disabled].active > a:only-child:after,
.ant-btn-dashed[disabled]:active > a:only-child:after,
.ant-btn-dashed[disabled]:focus > a:only-child:after,
.ant-btn-dashed[disabled]:hover > a:only-child:after,
.ant-btn-dashed[disabled] > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-danger {
  color: #d93026;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-danger:hover {
  color: #fff;
  background-color: #e65b4e;
  border-color: #e65b4e;
}
.ant-btn-danger:hover > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-danger:focus {
  color: #e65b4e;
  background-color: #fff;
  border-color: #e65b4e;
}
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:focus > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-danger.active,
.ant-btn-danger:active {
  color: #fff;
  background-color: #b31a17;
  border-color: #b31a17;
}
.ant-btn-danger.active > a:only-child,
.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger.active > a:only-child:after,
.ant-btn-danger:active > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-danger-disabled,
.ant-btn-danger-disabled.active,
.ant-btn-danger-disabled:active,
.ant-btn-danger-disabled:focus,
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled,
.ant-btn-danger.disabled.active,
.ant-btn-danger.disabled:active,
.ant-btn-danger.disabled:focus,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled],
.ant-btn-danger[disabled].active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-danger-disabled > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled].active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-danger-disabled.active > a:only-child:after,
.ant-btn-danger-disabled:active > a:only-child:after,
.ant-btn-danger-disabled:focus > a:only-child:after,
.ant-btn-danger-disabled:hover > a:only-child:after,
.ant-btn-danger-disabled > a:only-child:after,
.ant-btn-danger.disabled.active > a:only-child:after,
.ant-btn-danger.disabled:active > a:only-child:after,
.ant-btn-danger.disabled:focus > a:only-child:after,
.ant-btn-danger.disabled:hover > a:only-child:after,
.ant-btn-danger.disabled > a:only-child:after,
.ant-btn-danger[disabled].active > a:only-child:after,
.ant-btn-danger[disabled]:active > a:only-child:after,
.ant-btn-danger[disabled]:focus > a:only-child:after,
.ant-btn-danger[disabled]:hover > a:only-child:after,
.ant-btn-danger[disabled] > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-round {
  height: 32px;
  padding: 0 16px;
  font-size: 16px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 18px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
}
.ant-btn-circle-outline.ant-btn-lg,
.ant-btn-circle.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  border-radius: 50%;
}
.ant-btn-circle-outline.ant-btn-sm,
.ant-btn-circle.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 50%;
}
.ant-btn:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  pointer-events: none;
  content: "";
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-minus > svg,
.ant-btn .anticon.anticon-plus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading:before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  position: relative;
  padding-left: 29px;
  pointer-events: none;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)
  .anticon:not(:last-child) {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)
  .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn.active,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn.active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:hover {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 38px;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 22px;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn + .ant-btn-group,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group,
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group span + .ant-btn {
  margin-left: -1px;
}
.ant-btn-group
  .ant-btn-primary
  + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group
  > .ant-btn-group:first-child:not(:last-child)
  > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group
  > .ant-btn-group:last-child:not(:first-child)
  > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-btn:active > span,
.ant-btn:focus > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #1561ae;
  background-color: transparent;
  border-color: #1561ae;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
  color: #347cba;
  background-color: transparent;
  border-color: #347cba;
}
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary.active,
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #094487;
  background-color: transparent;
  border-color: #094487;
}
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled].active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #d93026;
  background-color: transparent;
  border-color: #d93026;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger:focus,
.ant-btn-background-ghost.ant-btn-danger:hover {
  color: #e65b4e;
  background-color: transparent;
  border-color: #e65b4e;
}
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger.active,
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #b31a17;
  background-color: transparent;
  border-color: #b31a17;
}
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled].active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-two-chinese-chars:first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > :not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  vertical-align: top;
}
a.ant-btn {
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-notification {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin: 0 24px 0 0;
}
.ant-notification-bottomLeft,
.ant-notification-topLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-bottomLeft
  .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft
  .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft
  .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-topLeft
  .ant-notification-fade-enter.ant-notification-fade-enter-active {
  animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(264px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin:before {
  display: block;
  content: "";
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.anticon.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #36b37e;
}
.anticon.ant-notification-notice-icon-info {
  color: #3053a9;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #d93026;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-appear,
.ant-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-fade-enter.ant-notification-fade-enter-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  to {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #36b37e;
}
.ant-message-error .anticon {
  color: #d93026;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #3053a9;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  display: none;
  color: #1561ae;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none\9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
  content: "";
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur:after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1561ae;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s linear infinite alternate;
}
.ant-spin-dot-item:first-child {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s linear infinite;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
  width: 14px;
  height: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
  width: 32px;
  height: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.antd-pro-components-exception-index-exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-imgBlock:after,
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-imgBlock:before {
  content: " ";
  display: table;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-imgBlock:after {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-imgEle {
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-content {
  flex: auto;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-content
  h1 {
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-content
  .antd-pro-components-exception-index-desc {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
}
.antd-pro-components-exception-index-exception
  .antd-pro-components-exception-index-content
  .antd-pro-components-exception-index-actions
  button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-exception-index-exception
    .antd-pro-components-exception-index-imgBlock {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-exception-index-exception {
    display: block;
    text-align: center;
  }
  .antd-pro-components-exception-index-exception
    .antd-pro-components-exception-index-imgBlock {
    margin: 0 auto 24px;
    padding-right: 0;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-exception-index-exception
    .antd-pro-components-exception-index-imgBlock {
    margin-bottom: -24px;
    overflow: hidden;
  }
}

table {
  margin: auto !important;
}
@media only screen and (max-width: 768px) {
  .antd-pro-components-global-header-index-header {
    margin: 12px !important;
    height: 66px !important;
  }
  table thead {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table tbody tr {
    display: block;
    border: 0 solid #ccc;
  }
  table tbody tr td {
    display: block;
    border: none;
    border-bottom: 1px solid #fff;
    position: relative;
    padding-left: 0 !important;
    white-space: normal;
    text-align: left;
  }
  table tbody tr td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    white-space: normal;
    font-weight: 700;
    content: attr(data-title);
  }
  .wider-headings tbody tr td {
    padding-left: 10 !important;
  }
  .ant-card {
    margin-bottom: 15px !important;
  }
  .ant-drawer-content-wrapper {
    width: 270px !important;
  }
  .ant-drawer.ant-drawer-left.ant-drawer-open aside {
    left: 0 !important;
  }
  .antd-pro-components-global-header-index-trigger {
    top: -24px !important;
    float: right !important;
    left: 10px !important;
  }
  .ant-breadcrumb,
  .antd-pro-components-global-header-index-logo {
    display: none !important;
  }
  .ant-layout-sider-children {
    position: relative;
  }
  .ant-layout-sider-children .fixed-sidebar {
    width: 40px !important;
  }
  .ant-layout-sider-children .fixed-sidebar:before {
    content: "";
    width: 40px !important;
  }
  .ant-layout-sider-children .fixed-sidebar .logo .ant-avatar-square {
    width: 25px !important;
    height: 25px !important;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo {
    padding-left: 70px !important;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo a h1 {
    padding: 0 0 0 5px;
    font-size: 13px;
  }
  .antd-pro-pages-app-chat-page-extraImg {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .antd-pro-components-global-header-index-header {
    margin: 12px !important;
    height: 66px !important;
  }
  .ant-card {
    margin-bottom: 15px !important;
  }
  .ant-layout .ant-card {
    margin-bottom: 25px !important;
  }
  .ant-drawer-content-wrapper {
    width: 270px !important;
  }
  .ant-layout-sider-children ul ul {
    margin-left: 0 !important;
  }
  .ant-drawer.ant-drawer-left.ant-drawer-open aside {
    left: 0 !important;
  }
  .antd-pro-components-global-header-index-trigger {
    top: -24px !important;
    float: right !important;
    left: 10px !important;
  }
  .ant-breadcrumb,
  .antd-pro-components-global-header-index-logo {
    display: none !important;
  }
  .ant-layout-sider-children {
    position: relative;
  }
  .ant-layout-sider-children .fixed-sidebar {
    width: 40px !important;
  }
  .ant-layout-sider-children .fixed-sidebar:before {
    content: "";
    width: 40px !important;
  }
  .ant-layout-sider-children .fixed-sidebar .logo .ant-avatar-square {
    width: 25px !important;
    height: 25px !important;
  }
  .ant-layout-sider-children
    .ant-layout-sider-collapsed
    .antd-pro-components-sider-menu-index-logo
    img {
    margin-left: -15px !important;
  }
  .ant-layout-sider-children
    .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    width: 250px !important;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo {
    padding-left: 12px !important;
    margin: 0;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo a img {
    position: relative;
    left: 0;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo a h1 {
    padding: 0 0 0 18px;
    font-size: inherit;
  }
  .antd-pro-pages-app-chat-page-extraImg {
    display: none;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .antd-pro-components-global-header-index-header {
    margin: 12px !important;
    height: 66px !important;
  }
  .ant-card {
    margin-bottom: 15px !important;
  }
  .ant-layout .ant-card {
    margin-bottom: 25px !important;
  }
  .ant-drawer-content-wrapper {
    width: 270px !important;
  }
  .ant-layout-sider-children ul ul {
    margin-left: 0 !important;
  }
  .ant-drawer.ant-drawer-left.ant-drawer-open aside {
    left: 0 !important;
  }
  .antd-pro-components-global-header-index-trigger {
    top: -24px !important;
    float: right !important;
    left: 10px !important;
  }
  .ant-breadcrumb,
  .antd-pro-components-global-header-index-logo {
    display: none !important;
  }
  .ant-layout-sider-children {
    position: relative;
  }
  .ant-layout-sider-children .fixed-sidebar {
    width: 40px !important;
  }
  .ant-layout-sider-children .fixed-sidebar:before {
    content: "";
    width: 40px !important;
  }
  .ant-layout-sider-children .fixed-sidebar .logo .ant-avatar-square {
    width: 25px !important;
    height: 25px !important;
  }
  .ant-layout-sider-children
    .ant-layout-sider-collapsed
    .antd-pro-components-sider-menu-index-logo
    img {
    margin-left: -15px !important;
  }
  .ant-layout-sider-children
    .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    padding: 0;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo {
    padding-left: 12px !important;
    margin: 0;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo a img {
    position: relative;
    left: 0;
  }
  .ant-layout-sider-children .antd-pro-components-sider-menu-index-logo a h1 {
    padding: 0 0 0 18px;
    font-size: inherit;
  }
  .antd-pro-pages-app-chat-page-extraImg {
    display: none;
  }
}
#root,
body,
html {
  height: 100%;
  font-family: Montserrat, sans-serif;
  color: #979da7 !important;
  line-height: 1.8 !important;
}
.ant-card,
.ant-list {
  line-height: 1.8 !important;
}
.colorWeak {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.ant-layout {
  min-height: 100vh;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.globalSpin {
  width: 100%;
  margin: 40px 0 !important;
}
ol,
ul {
  list-style: none;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #eee !important;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu-title-selected > a {
  background-color: #eee !important;
}
.ant-menu-vertical .ant-menu-submenu-selected {
  color: #1890ff !important;
}
.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #1890ff !important;
}
.ant-menu-inline-collapsed .ant-menu-dark .ant-menu-sub {
  background: #fff;
}
.ant-menu-inline-collapsed .ant-menu-dark .ant-menu-sub a {
  color: #444 !important;
}
.ant-menu-inline-collapsed .ant-menu-dark .ant-menu-sub a:hover {
  color: #1890ff !important;
}
.ant-menu-inline-collapsed
  .ant-menu-dark
  .ant-menu-sub
  .ant-menu-item-selected
  a {
  color: #1890ff !important;
  background: transparent !important;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo img {
  margin-left: 14px;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-AvantProfile {
  min-height: 60px;
  position: relative;
  display: block;
  padding: 24px 0;
}
.ant-layout-sider-collapsed
  .antd-pro-components-sider-menu-index-AvantProfile
  .antd-pro-components-sider-menu-index-AvantAvatar {
  margin: 0 auto;
  text-align: center;
}
.ant-layout-sider-collapsed
  .antd-pro-components-sider-menu-index-AvantProfile
  .antd-pro-components-sider-menu-index-AvantAvatar
  .ant-badge-count {
  box-shadow: 0 0 0 2px #fff;
}
.ant-layout-sider-collapsed
  .antd-pro-components-sider-menu-index-AvantProfile
  h1,
.ant-layout-sider-collapsed
  .antd-pro-components-sider-menu-index-AvantProfile
  p {
  display: none;
}
.ant-layout-sider-collapsed
  .antd-pro-components-sider-menu-index-AvantProfile
  ul {
  display: none;
  margin: 24px 0 0;
}
.ant-layout-sider-collapsed
  .antd-pro-components-sider-menu-index-AvantProfile
  ul
  li {
  display: block;
}
.ant-layout-sider-collapsed
  .antd-pro-components-sider-menu-index-AvantProfile
  ul
  li
  i {
  color: #fff;
  font-size: 16px;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  color: #1890ff !important;
  background: #fff;
  border-radius: 4px;
}
.ant-menu-submenu > .ant-menu {
  border-radius: 0;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 12px !important;
}
.ant-menu-vertical > .ant-menu-item {
  height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
  min-height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-vertical-left > .ant-menu-item {
  height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
  min-height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-vertical-right > .ant-menu-item {
  height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
  min-height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-inline > .ant-menu-item {
  height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
  min-height: 48px !important;
  line-height: 48px !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  bottom: 25px !important;
  top: unset !important;
}
.ant-layout-header {
  background: #fff !important;
}
.ant-menu-vertical li a span div:empty {
  display: none !important;
}
.ant-layout {
  background: #f3f5f8 !important;
  position: relative;
  z-index: 0;
}
.ant-layout-sider-children ul {
  background: transparent !important;
}
.ant-layout-sider-children ul li.ant-menu-item {
  padding-left: 24px !important;
}
.ant-layout-sider-children ul li.ant-menu-item a {
  font-weight: 300;
}
.ant-layout-sider-children ul li.ant-menu-item a span {
  padding: 0 0 0 22px;
}
.ant-layout-sider-children ul li.ant-menu-item a span div {
  margin-right: 0 !important;
}
.ant-layout-sider-children ul li.ant-menu-item a span div:empty {
  display: none !important;
}
.ant-layout-sider-children ul li.ant-menu-item a span small {
  float: right;
  height: 20px;
  display: inline-block;
  min-width: 30px;
  line-height: 20px;
  text-align: center;
  border-radius: 25px;
  margin-top: 12px;
  font-size: 14px;
  background: transparent;
}
.ant-menu-dark {
  background: transparent !important;
}
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #1890ff !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: hsla(0, 0%, 100%, 0.65);
}
.ant-menu-item a {
  display: block;
  padding: 0;
  text-transform: capitalize;
}
.ant-menu-item a:hover {
  transition: opacity 0.35s, transform 0.35s;
}
.ant-menu-vertical .ant-menu-item:after {
  border-right: 3px solid transparent !important;
}
.ant-menu-vertical-left .ant-menu-item:after {
  border-right: 3px solid transparent !important;
}
.ant-menu-vertical-right .ant-menu-item:after {
  border-right: 3px solid transparent !important;
}
.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid transparent !important;
}
.ant-layout .ant-card {
  border-radius: 6px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08);
}
.ant-menu-submenu-title:hover {
  color: #1890ff !important;
}
.ant-breadcrumb {
  position: absolute;
  left: 95px;
  padding: 6px 20px !important;
  border-radius: 20px;
  top: 18px;
  font-size: 13px !important;
  text-transform: capitalize;
  background: #ebeef3;
}
.ant-breadcrumb span {
  color: #7887a9;
}
.ant-breadcrumb span a {
  color: #7887a9;
  position: relative;
  top: 2px;
}
.ant-breadcrumb span .ant-breadcrumb-separator {
  position: relative;
  top: 0;
}
.ant-breadcrumb span span {
  color: #7887a9;
}
.ant-layout-sider-children {
  position: relative;
}
.ant-layout-sider-children .ant-menu {
  font-weight: 300;
}
.ant-layout-sider-children .ant-menu li ul li a span div:empty {
  display: none !important;
}
.ant-layout-sider-children .ant-menu li div span .ant-tag {
  display: block;
  border: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 36px 0 12px;
  background: #08090d;
  padding: 3px 12px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
}
.ant-layout-sider-children .ant-menu li div span .ant-tag:empty {
  display: none !important;
}
.ant-layout-sider-children .ant-menu-inline-collapsed li div span .ant-tag {
  display: none !important;
}
.ant-layout-sider-children
  .ant-menu-inline-collapsed
  li
  div
  span
  .ant-tag:empty {
  display: none !important;
}
.ant-layout-sider-children
  .ant-menu-inline
  li.ant-menu-item
  .ant-menu-item-selected {
  border-radius: 6px;
  margin: 0;
}
.ant-layout-sider-children .ant-menu-inline li {
  border-radius: 6px;
  margin: 0;
}
.ant-layout-sider-children .ant-menu-inline li .ant-menu-sub {
  box-shadow: none;
  border-radius: 0;
}
.ant-layout-sider-children .ant-menu-inline li .ant-menu-sub li {
  margin: 0;
  border-radius: 0;
}
.ant-layout-sider-children
  .ant-menu-inline
  li
  .ant-menu-sub
  li
  div.ant-menu-submenu-title {
  border-radius: 0;
  padding-left: 63px !important;
}
.ant-layout-sider-children
  .ant-menu-inline
  li
  .ant-menu-sub
  li
  div.ant-menu-submenu-title:hover {
  color: #1890ff !important;
}
.ant-layout-sider-children .ant-menu-inline li .ant-menu-sub li ul li {
  background: #090c11 !important;
  border-radius: 0;
  padding-left: 60px !important;
}
.ant-layout-sider-children .ant-menu-inline li .ant-menu-sub li ul li a {
  font-weight: 300;
}
.ant-layout-sider-children
  .ant-menu-inline
  li
  .ant-menu-sub
  li.ant-menu-submenu.ant-menu-submenu-inline
  div {
  border-radius: 0 0 5px 5px !important;
}
.ant-layout-sider-children
  .ant-menu-inline
  li
  .ant-menu-sub
  li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  div {
  border-radius: 0 !important;
}
.ant-layout-sider-children
  .ant-menu-inline
  li
  .ant-menu-sub
  li.ant-menu-item-selected {
  background: rgba(24, 144, 255, 0.2) !important;
  position: relative;
  z-index: 0;
}
.ant-layout-sider-children
  .ant-menu-inline
  li
  .ant-menu-sub
  li.ant-menu-item-selected:before {
  content: "";
  border-left: 3px solid #1890ff;
  display: block;
  height: 48px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.ant-layout-sider-children
  .ant-menu-inline
  li
  .ant-menu-sub
  li.ant-menu-item-selected
  a {
  color: #fff !important;
  font-weight: 300;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-item {
  margin: 0;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-item a i {
  font-size: 16px !important;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-item a span {
  padding: 0 0 0 22px;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-item a span span {
  color: #fff;
  padding: 0;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-item a span span sup {
  box-shadow: none;
}
.ant-layout-sider-children
  .ant-menu-inline
  li.ant-menu-item.ant-menu-item-selected
  a {
  padding: 0;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-submenu-open {
  box-shadow: none;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-submenu-open div {
  background: #0f121a;
  margin: 0;
  border-radius: 0;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-submenu-open div span {
  color: #fff;
  font-weight: 500;
}
.ant-layout-sider-children .ant-menu-inline li.ant-menu-submenu-open div i {
  color: #fff;
}
.ant-layout-sider-children
  .ant-menu-inline
  li.ant-menu-submenu-open
  ul
  li.ant-menu-item {
  background: #0f121a;
}
.ant-layout-sider-children
  .ant-menu-inline
  li.ant-menu-submenu-open
  ul
  li.ant-menu-item:last-child {
  border-radius: 0;
  margin-bottom: 24px;
  display: inline-table;
}
.ant-layout-sider-children
  .ant-menu-inline
  li.ant-menu-submenu-open
  ul
  li.ant-menu-item
  ul
  li.ant-menu-item {
  background: #0b0e14 !important;
}
.ant-layout-sider-children
  .ant-menu-inline
  li.ant-menu-submenu-open
  ul
  li.ant-menu-item
  ul
  li.ant-menu-item
  ul
  li {
  background: #0b0e14 !important;
}
.list-notif-avant .ant-tag {
  font-size: 11px;
  border-radius: 30px;
  border: none;
  padding: 0 15px;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 360px;
  line-height: 360px;
  background: #364d79;
  overflow: hidden;
  border-radius: 5px;
}
.ant-carousel .slick-slide h3 {
  color: #fff;
}
.ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-header {
  background-color: #1561ae;
  border-radius: 0;
  color: #fff;
}
.ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-title {
  color: #fff !important;
}
.footer-avant-btn {
  height: 46px;
  line-height: 46px;
  text-align: center;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  transition: all 0.3s ease 0s;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
}
.footer-avant-btn span {
  float: left;
  text-align: center;
  width: 50%;
  cursor: pointer;
}
.footer-avant-btn span:first-child {
  border-right: 1px solid #e8e8e8;
}
.icon-color-antd .ant-card .ant-card-head {
  font-size: 12px !important;
  text-align: center;
}
.icon-color-antd .ant-card .ant-card-body {
  text-align: center;
}
.ant-menu-submenu-vertical span span {
  display: none;
}
.ant-menu-submenu-inline .dripicons {
  font-size: inherit;
  margin-right: 24px;
  position: relative;
  top: 2px;
}
.ant-menu-submenu-inline .ant-menu-submenu-title .anticon,
.ant-menu-submenu-inline .ant-menu-submenu-title .dripicons {
  margin-right: 24px;
  font-size: 16px !important;
  top: 2px;
  position: relative;
}
.ant-menu-submenu-inline .ant-menu-submenu-title:hover {
  color: #fff !important;
}
.ant-alert-info {
  background-color: rgba(82, 78, 77, 0.15) !important;
  border: 1px solid #afa9a9 !important;
}
.avatar-list-avant {
  margin: 0 0 20px;
}
.avatar-list-avant h4 {
  color: #1561ae;
  display: table;
  padding: 3px 0 10px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}
.avatar-list-avant h4:after {
  content: "";
  background-color: #1561ae;
  display: block;
  height: 4px;
  border-radius: 9px;
  width: 25px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.avatar-list-avant .ant-avatar {
  margin: 0 10px 0 0 !important;
}
code {
  margin: 0 1px;
  padding: 0.2em 0.4em;
  font-size: 0.9em;
  background: #f2f4f5;
  border: 1px solid #eee;
  border-radius: 3px;
}
.smooth-dnd-container * {
  border: 0 !important;
  font-size: inherit !important;
}
.smooth-dnd-container * .comPlainTextContentEditable {
  -webkit-user-modify: read-write-plaintext-only;
  padding: 8px 5px;
}
.smooth-dnd-container * .eoPYBZ,
.smooth-dnd-container * .jHtXmx {
  max-width: 100% !important;
}
.dJewwL {
  background-color: #c1d3f5 !important;
}
.iTzKeC {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  cursor: -webkit-grab;
  cursor: grab;
  width: 70%;
  background: rgba(0, 0, 0, 0.21) !important;
  display: table !important;
  color: #fff !important;
  padding: 7px 0 7px 15px !important;
  border-radius: 40px !important;
}
.demo-description {
  max-width: 500px;
}
.demo-description img {
  max-width: 500px;
}
.demo-test-area {
  width: 300px;
  box-sizing: border-box;
}
.demo-test-area--text {
  box-sizing: border-box;
  width: 100%;
  background: #fafbfc;
  color: #8da2b5;
  border: 1px solid #dde5ed;
  padding: 12px 20px;
  border-radius: 40px;
  outline: none;
  height: 50px;
  margin: 0 0 10px;
  overflow: hidden;
}
.demo-monster-img {
  width: 400px;
  display: block;
  margin: 60px auto;
}
.demo-test-area--wrapper {
  padding: 0;
  width: 100%;
}
.demo-test-area--title {
  align-self: center;
  text-align: center;
}
.demo-test-area--title-main {
  color: #516996;
  font-size: 28px;
  font-weight: 600;
}
.demo-test-area--title-sub {
  color: #4e8cff;
  padding-top: 10px;
}
.demo-test-area--title-sub a {
  color: #4e8cff;
  font-weight: 400;
  text-decoration: none;
}
.demo-test-area {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.demo-test-area--preamble {
  color: #516996;
  padding: 20px 0;
}
.demo-test-area--button {
  align-self: center;
  background: #4e8cff;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  font-weight: 400;
  height: 50px;
  border-radius: 50%;
  width: 50px;
  line-height: 60px;
  margin: 0 !important;
  outline: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.demo-test-area--button:hover {
  background: #1890ff !important;
}
.demo-test-area--info {
  font-weight: 200;
  margin: auto;
  width: 100%;
  display: block;
}
.demo-test-area--info a {
  color: #4e8cff;
  font-weight: 400;
  text-decoration: none;
}
.rce-mbox-photo--img img {
  width: 300px;
  height: 100%;
  min-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rce-citem-body--bottom-status span {
  width: 24px !important;
  height: 24px !important;
  background: #e8837d !important;
}
.rce-mbox-time-block {
  padding-left: 5px !important;
  color: inherit !important;
}
.rce-mbox-photo--img {
  border-radius: 6px !important;
}
.rce-mbox-photo--img__block {
  background: rgba(21, 97, 174, 0.2) !important;
  border-radius: 0 !important;
}
.rce-mbox-time-block {
  background: transparent !important;
  border-radius: 0 !important;
}
.rce-mbox-left-notch {
  fill: #fff !important;
}
.rce-mbox-photo {
  padding: 6px;
}
.rce-mbox {
  background-color: #fff !important;
  box-shadow: none !important;
  padding: 12px !important;
}
.rce-mbox-right-notch {
  fill: #6eaeee !important;
}
.rce-mbox.rce-mbox-right {
  background: #6eaeee !important;
  color: #fff;
  box-shadow: none !important;
}
.fWwGFt {
  font-weight: 500 !important;
  color: #63b4ff !important;
}
.EEFAf {
  background: #fff !important;
}
.bnWttt,
.iHJMOK {
  font-weight: 500 !important;
}
.dzWFHY {
  background-color: #fff !important;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08);
}
.eEqJio {
  background: #f3f5f8 !important;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: #fff !important;
}
.ant-drawer-content {
  background: #f3f5f8 !important;
}
.ant-tag {
  border-radius: 20px !important;
}
.ant-tabs-bar {
  background: #fff !important;
}
.ant-tag-green {
  color: #fff !important;
  background: #54e0ae !important;
  border-color: #54e0ae !important;
}
.ant-tag-yellow {
  color: #fff !important;
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.conversion-spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-check-input {
  cursor: pointer;
}



